import styled from 'styled-components';

export const Spacing = styled.div`
  ${({ size }) => {
    switch (size) {
      case 'small':
        return 'margin-bottom: 12px;';
      case 'medium':
        return 'margin-bottom: 32px;';
      case 'large':
        return 'margin-bottom: 64px;';
      default:
        return '';
    }
  }}
`;
